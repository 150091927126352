<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner title="MASTER DISTRIBUTERS" :breadcrumb="[
          {
            label: 'Master Distributers',
          },
          { label: 'Master Distributers List' },
        ]">
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card mb-1">

              <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <strong>Master Distributers</strong>
                  </div>
                  <div class="col text-end ">
                    <button type="button" @click="loadFormNull()" data-bs-toggle="modal"
                      data-bs-target="#masterDistributerModal" title="Add New" style="background-color: #f21000"
                      class="btn text-white btn-sm">+ New</button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>
                <!-- start list area  -->
                <div class="row">



                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top: 7px">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Firm Name</th>
                          <th>email</th>
                          <th>Phone</th>

                          <th class="text-center">Kyc Status</th>
                          <th class="text-center">Status</th>
                          <th class="text-end">Action</th>
                        </tr>

                        <tbody>
                          <!-- v-for="(masterDistributer, index) in masterDistributers" :key="index" -->
                          <tr style="color: #00364f" v-for="(masterDistributer, index) in masterDistributers"
                            :key="index">
                            <td>
                              <img style="width: 30px; height: 30px" :src="profilePhotoFinder(masterDistributer)"
                                alt="" />
                            </td>

                            <td class="text-truncate" v-if="masterDistributer">
                              {{ masterDistributer.code }}
                            </td>
                            <td class="text-truncate" v-if="masterDistributer">
                              {{ masterDistributer.name }}
                            </td>
                            <td class="text-truncate" v-if="masterDistributer">
                              {{ masterDistributer.bussinessName }}
                            </td>
                            <td class="text-truncate" v-if="masterDistributer">
                              {{ masterDistributer.email }}
                            </td>
                            <td class="text-truncate" v-if="masterDistributer">
                              {{ masterDistributer.phone }}
                            </td>

                            <td class="text-end">
                              <span v-if="masterDistributer.master_distributer_kyc">
                                <p v-if="masterDistributer.master_distributer_kyc.kycStatus ==
                                  'approve'
                                  " class="text-white btn btn-sm" style="
                                    width: 80px;
                                    background-color: green;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <strong> Approve</strong>
                                </p>
                                <p v-if="masterDistributer.master_distributer_kyc.kycStatus ==
                                  'reject'
                                  " class="text-white btn btn-sm" style="
                                    width: 80px;
                                    background-color: black;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <strong> Rejected</strong>
                                </p>
                                <p v-if="masterDistributer.master_distributer_kyc.kycStatus ==
                                  'cancel'
                                  " class="text-white btn btn-sm" style="
                                    width: 80px;
                                    background-color: red;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <strong> cancelled</strong>
                                </p>

                                <p v-if="masterDistributer.master_distributer_kyc.kycStatus ==
                                  'false'
                                  " class="text-white btn btn-sm" style="
                                    width: 80px;
                                    background-color: rgb(255, 145, 0);
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <strong> Pending </strong>
                                </p>
                              </span>

                              <span v-else>
                                <p class="text-white btn btn-sm" style="
                                    width: 80px;
                                    background-color: rgb(255, 145, 0);
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <strong> Pending </strong>
                                </p>
                              </span>
                            </td>

                            <td class="text-end">
                              <p v-if="masterDistributer.loginStatus == 'true'" class="text-white btn btn-sm" style="
                                  width: 80px;
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <strong> Active</strong>
                              </p>
                              <p v-else class="text-white btn btn-sm" style="
                                  width: 80px;
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <strong> InActive</strong>
                              </p>
                            </td>
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link title="View"
                                  :to="`/admin/master-distributer-profile/${masterDistributer.id}`"
                                  class="btn btns btn-sm text-white" style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  ">
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <router-link to="#" title="Edit" @click="masterDistributerValueAssign(masterDistributer)"
                                  data-bs-toggle="modal" data-bs-target="#masterDistributerModal"
                                  class="btn btns btn-sm btn-success" style="padding: 5px 4.5px 5px 4.5px">
                                  <font-awesome-icon icon="edit" />
                                </router-link>
                                <router-link to="#" data-bs-toggle="modal" @click="
                                  masterDistributerkycValueAsign(masterDistributer)
                                  " title="Kyc" data-bs-target="#kycModal" class="btn btns btn-sm text-dark" style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: yellow;
                                  ">
                                  <font-awesome-icon icon="file" />
                                </router-link>
                                <button title="Verify" v-if="masterDistributer.master_distributer_kyc" @click="
                                  valueAssign(
                                    masterDistributer.master_distributer_kyc,
                                    masterDistributer.id
                                  )
                                  " class="btn btns btn-sm" style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #1426f5;
                                    color: white;
                                  " data-bs-toggle="modal" data-bs-target="#approoveKycModal">
                                  <b>
                                    <font-awesome-icon icon="tag" />
                                  </b>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>


              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadMasterDistributers(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadMasterDistributers(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadMasterDistributers(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadMasterDistributers(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadMasterDistributers(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadMasterDistributers(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadMasterDistributers(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadMasterDistributers(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadMasterDistributers(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadMasterDistributers( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadMasterDistributers( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadMasterDistributers(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadMasterDistributers(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadMasterDistributers(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadMasterDistributers(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadMasterDistributers(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>




            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="masterDistributerModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Master Distributer</strong>
          </h5>
          <button @click="loadMasterDistributers()" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span v-if="msgImgError">
            <div class="alert alert-danger p-1" role="alert">
              {{ msgImgError }}
            </div>
          </span>
          <form @submit.prevent="
            edit == 'false' ? createMasterDistributer() : masterDistributerrUpdate()
            ">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-md-12">
                <label for="file-input1">
                  <img style="width: 60px; height: 60px" :src="photo" alt="image" />
                </label>
                <input @change="uploadImg" class="input-file" style="position: absolute; top: -150000px" id="file-input1"
                  type="file" accept="image/*" />
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="name">Name <small style="color: #f21000">*</small></label>
                  <input type="text" v-model="form.name" class="form-control" placeholder="Name" id="name"
                    style="height: 30px" required @change="lenthheker($event, 'professionalName')" />
                  <span style="color: #f21000" id="professionalName"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.name">{{
                          form.errors.error.name[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="dob">DOB <small style="color: #f21000">*</small></label>
                  <input type="date" v-model="form.dob" class="form-control" placeholder="Date of Birth" id="dob"
                    style="height: 30px" required />
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.dob">{{
                          form.errors.error.dob[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="gender">Gender <small style="color: #f21000">*</small></label>
                  <select required v-model="form.gender" style="height: 30px; padding: 5px 5px 5px 5px"
                    class="form-select" aria-label="Default select example">
                    <option value="male" selected>Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="phone">Phone <small style="color: #f21000">*</small></label>
                  <input type="number" v-model="form.phone" class="form-control" placeholder="Phone Number" id="phone"
                    style="height: 30px" required @change="lenthheker($event, 'phonePro')" />
                  <span style="color: #f21000" id="phonePro"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.phone">{{
                          form.errors.error.phone[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="altPhone">Alternative Phone </label>
                  <input type="number" v-model="form.alternativePhone" class="form-control"
                    placeholder="Alternative Phone number" id="altPhone" style="height: 30px"
                    @change="lenthheker($event, 'altPhonePro')" />
                  <span style="color: #f21000" id="altPhonePro"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.alternativePhone">{{
                          form.errors.error.alternativePhone[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="email">Email <small style="color: #f21000">*</small></label>
                  <input type="email" v-model="form.email" class="form-control" placeholder="Email Address" id="email"
                    style="height: 30px" required />
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.email">{{
                          form.errors.error.email[0]
                        }}</span></span></small>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" v-model="form.password" class="form-control" placeholder="Password" id="password"
                    style="height: 30px" @change="lenthheker($event, 'proPassword')" />
                  <span style="color: #f21000" id="proPassword"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.password">{{
                          form.errors.error.password[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="address">Address <small style="color: #f21000">*</small></label>
                  <input type="text" v-model="form.address" class="form-control" placeholder="Address " id="address"
                    style="height: 30px" required @change="lenthheker($event, 'profesionalAddress')" />
                  <span style="color: #f21000" id="profesionalAddress"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.address">{{
                          form.errors.error.address[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="state">State <small style="color: #f21000">*</small></label>

                  <select v-model="form.state_id" class="form-select" aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px" required>
                    <option selected disabled>slect..</option>
                    <option :value="state.id" v-for="(state, index) in allstates" :key="index">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="city">City <small style="color: #f21000">*</small></label>
                  <input required type="text" v-model="form.city" class="form-control" placeholder="City" id="city"
                    style="height: 30px" @change="lenthheker($event, 'profesionalCity')" />
                  <span style="color: #f21000" id="profesionalCity"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="pin">Pin Code <small style="color: #f21000">*</small></label>
                  <input required type="number" v-model="form.pin" class="form-control" placeholder="Pin Code" id="pin"
                    style="height: 30px" @change="lenthheker($event, 'pinCode')" />
                  <span style="color: #f21000" id="pinCode"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.pin">{{
                          form.errors.error.pin[0]
                        }}</span></span></small>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="specialization">Specialization </label>
                  <input v-model="form.specialization" type="text" class="form-control" placeholder="Specialization"
                    id="specialization" style="height: 30px" />
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.specialization">{{
                          form.errors.error.specialization[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="status">Status </label>
                  <select v-model="form.loginStatus" class="form-select" aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px">
                    <option selected disabled>Status...</option>
                    <option value="false">InActive</option>
                    <option value="true">Active</option>
                  </select>
                </div>
              </div>

              <h4 class="mt-1" style="color: #00364f">Bussiness Details</h4>
              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="bussinessPan">Bussiness Pan </label>
                  <span v-if="panVerifyForFirm == 'true'">
                    <input disabled v-model="form.bussinessPan" type="tel" maxlength="10" minlength="10" class="form-control"
                      placeholder="Bussiness Pan" id="bussinessPan" style="height: 30px" @change="panVeryfied()"
                      @keydown="nameKeydown($event)" />
                  </span>
                  <span v-else>
                    <input v-model="form.bussinessPan" 
                    type="tel" maxlength="10" minlength="10" class="form-control" placeholder="Bussiness Pan"
                      id="bussinessPan" style="height: 30px" @change="panVeryfied()" @keydown="nameKeydown($event)" />
                  </span>

                  <small style="color: red" id="firmPanVeryfied"></small>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.bussinessPan">{{
                          form.errors.error.bussinessPan[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="bussinessName">Firm Name </label>
                  <input v-model="form.bussinessName" type="text" class="form-control" placeholder="Firm Name"
                    id="bussinessName" style="height: 30px" />
                  <!-- @change="lenthheker($event, 'bussinessNameMaster')" -->
                  <span style="color: #f21000" id="bussinessNameMaster"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.bussinessName">{{
                          form.errors.error.bussinessName[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="bussinessAddress"> Firm Address</label>
                  <input type="text" v-model="form.bussinessAddress" class="form-control" placeholder="Firm Address "
                    id="bussinessAddress" style="height: 30px" />
                  <!-- required
                    @change="lenthheker($event, 'businessAddressMaster')" -->
                  <span style="color: #f21000" id="businessAddressMaster"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="state">Firm State</label>
                  <!-- <small style="color: #f21000">*</small> -->
                  <select v-model="form.bussiness_state_id" class="form-select" aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px">
                    <option selected disabled>slect..</option>
                    <option :value="state.id" v-for="(state, index) in allstates" :key="index">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="bussinessCity">Firm City</label>
                  <input type="text" v-model="form.bussinessCity" class="form-control" placeholder="Firm City"
                    id="bussinessCity" style="height: 30px" />
                  <!-- @change="lenthheker($event, 'bussinessCityMaster')" -->
                  <span style="color: #f21000" id="bussinessCityMaster"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="bussinessPin">Bussiness Pin Code</label>
                  <input type="number" v-model="form.bussinessPin" class="form-control" placeholder="Bussiness Pin Code"
                    id="bussinessPin" style="height: 30px" />
                  <!-- @change="lenthheker($event, 'businessPinCode')" -->
                  <!-- <small style="color: #f21000">*</small> -->
                  <span style="color: #f21000" id="businessPinCode"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.bussinessPin">{{
                          form.errors.error.bussinessPin[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="gstin">GSTIN </label>
                  <input type="tel" maxlength="15" minlength="15" v-model="form.gstin" class="form-control" placeholder="GSTIN"
                    id="gstin" style="height: 30px" />
                  <!-- @change="lenthheker($event, 'gstinVal')" -->
                  <span style="color: #f21000" id="gstinVal"></span>
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.gstin">{{
                          form.errors.error.gstin[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="doi">DOI </label>
                  <input type="date" v-model="form.doi" class="form-control" placeholder="Pin Code" id="doi"
                    style="height: 30px" />
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.doi">{{
                          form.errors.error.doi[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="cin">CIN</label>
                  <input type="tel" maxlength="21" minlength="21" v-model="form.cin" class="form-control" placeholder="CIN" id="cin"
                    style="height: 30px" />
                  <small style="color: red"  v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.cin">{{
                          form.errors.error.cin[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-lg-3 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="tds">TAN</label>
                  <input type="tel" maxlength="10" minlength="10" v-model="form.tds" class="form-control" placeholder="TAN" id="tds"
                    style="height: 30px" />
                  <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                        v-if="form.errors.error.tds">{{
                          form.errors.error.tds[0]
                        }}</span></span></small>
                </div>
              </div>

              <div class="col-xl-12 col-md-12 col-lg-12 col-12" style="padding: 3px 3px 3px 3px">
                <div class="form-group">
                  <label for="remark">Remark</label>
                  <!-- <input type="text" class="form-control" placeholder="Remark" id="remark" style="height:32px;"> -->
                  <textarea v-model="form.remark" class="form-control" placeholder="Remark" rows="3" style=""></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                <strong>Confirm</strong>
              </button>

              <button @click="loadMasterDistributers()" type="button" class="btn btn-sm text-white"
                style="background-color: #f21300" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="kycModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="exampleModalLabel">
            <b>Mastre Distributer Kyc</b>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="msgImgError">
            <div class="col">
              <div class="alert alert-danger p-1" role="alert">
                {{ msgImgError }}
              </div>
            </div>
          </div>
          <form @submit.prevent="
            masterdistributerKycVal == 'false'
              ? masterDistributerkycCreate()
              : masterDistributerkycUpdate()
            ">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th>Action</th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="adharFrontImg" :src="adharFrontImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Aadhaar Front</b>
                    <input @change="uploadAdharFront" class="input-file" style="position: absolute; top: -150000px"
                      id="AdharFront" type="file" accept="image/*" />
                    <input type="number" v-model="formKyc.aadharNo" class="form-control" placeholder="Adhar Number"
                      style="height: 30px" @change="lenthheker($event, 'professionalKycAddar')" required />
                    <span style="color: #f21000" id="professionalKycAddar"></span>
                    <small style="color: red" v-if="formKyc.errors"><span v-if="formKyc.errors.error"><span
                          v-if="formKyc.errors.error.aadharNo">{{
                            formKyc.errors.error.aadharNo[0]
                          }}</span></span></small>
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharFront">
                      <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="adharBackImg" :src="adharBackImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Aadhaar Back</b>
                    <input @change="uploadAdharBack" class="input-file" style="position: absolute; top: -150000px"
                      id="AdharBack" type="file" accept="image/*" />
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharBack">
                      <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Pan</b>
                    <input @change="uploadPanPhoto" class="input-file" style="position: absolute; top: -150000px"
                      id="panphoto" type="file" accept="image/*" />
                    <!-- <input
                      type="tel"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px"
                      maxlength="10"
                      required
                    /> -->

                    <input v-if="panVarifyingStatus == 'true'" disabled type="tel" maxlength="10" v-model="formKyc.panNo"
                      class="form-control" placeholder="Pan Number" style="height: 30px" required
                      @change="businessPanVeryfied()" @keydown="nameKeydown($event)" />
                    <input v-else type="tel" maxlength="10" v-model="formKyc.panNo" class="form-control"
                      placeholder="Pan Number" style="height: 30px" required @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)" />
                    <span style="color: #f21000" id="adminPanVeryfied"></span>

                    <small style="color: red" v-if="formKyc.errors"><span v-if="formKyc.errors.error"><span
                          v-if="formKyc.errors.error.panNo">{{
                            formKyc.errors.error.panNo[0]
                          }}</span></span></small>
                  </td>
                  <td class="text-truncate text-end">
                    <label for="panphoto">
                      <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="background-color: #f21300"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn text-white btn-sm" style="background-color: #00364f">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="approoveKycModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong class="text">Approve Kyc</strong>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="adharFrontImg" :src="adharFrontImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select style="height: 30px; padding: 5px 5px 5px 5px" @change="aadharKycApprovedSave()"
                      class="form-select" v-model="aadharapproveKyc.status">
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button @click="
                      extensionkycAdharFront.toLowerCase() == 'pdf'
                        ? openWindow(adharFrontImgPdf)
                        : openWindow(adharFrontImg)
                      " style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm">
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="adharBackImg" :src="adharBackImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button @click="
                      extensionkycAdharBack.toLowerCase() == 'pdf'
                        ? openWindow(adharBackImgPdf)
                        : openWindow(adharBackImg)
                      " style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm">
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select style="height: 30px; padding: 5px 5px 5px 5px" v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()" class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button @click="
                      extensionkycPanPhoto.toLowerCase() == 'pdf'
                        ? openWindow(panImgPdf)
                        : openWindow(panImg)
                      " style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm">
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Profesional Kyc</b>
                    <select style="height: 30px; padding: 5px 5px 5px 5px" v-model="approveKyc.status"
                      class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea v-model="approveKyc.remark" rows="2" class="form-control" placeholder="Remark"></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <button type="button" @click="masterDistributerKycApprovedSave()" class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f">
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="background-color: #f21300"
                data-bs-dismiss="modal">
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" novalidate onsubmit="return false">
          <div class="modal-header align-items-center mb-1" style="background-color: #00364f">
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white">
              <span class="todo-item-favorite cursor-pointer me-75 text-white"><i data-feather="star"
                  class="font-medium-2"></i></span>
              <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal" stroke-width="3">X</i>
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="code1" />
                  <label class="form-check-label" for="code1"> Code </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
    
    Orgnization
  </label>
</div>
</li> -->
              <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="name1" />
                  <label class="form-check-label" for="name1"> Name </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="email1" />
                  <label class="form-check-label" for="email1"> email </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="phone1" />
                  <label class="form-check-label" for="phone1"> phone </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="all1" />
                  <label class="form-check-label" for="all1"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
              <input :type="filterType == 'phone' ? 'number' : 'text'" v-if="filterType != 'all'" class="form-control"
                v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value" />
              <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
            </div>

            <button @click="loadMasterDistributers()" type="button" class="btn btn-success btn-sm">
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/admin/comman/Banner.vue";

import Spinner from "../../../components/admin/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "MasterDistributers",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      extensionProfile: "",
      filterValue: "",
      filterType: "",
      msgImgError: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImgPdf: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      edit: "false",
      loading: false,
      allstates: [],
      masterDistributers: [],
      form: new Form({
        photo: "",
        name: "",
        email: "",
        password: "",
        phone: "",
        code: "",
        dob: "",
        pin: "",
        address: "",
        gender: "",
        alternativePhone: "",
        specialization: "",
        state_id: null,
        city: "",
        loginStatus: "",
        remark: "",

        bussinessName: "",
        bussinessCity: "",
        bussinessPan: "",
        bussinessPin: "",
        bussiness_state_id: null,
        bussinessAddress: "",

        errors: {},
      }),
      // photo : '',
      photo: this.$store.state.placeholderImg,
      panVarifyingStatus: "false",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      masterdistributerKycVal: "false",
      formKyc: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      }),
      masterDistributer_id: null,

      approveKyc: new Form({
        master_distributer_id: null,
        type: "kyc",
        status: "",
        remark: "",
      }),

      aadharapproveKyc: new Form({
        master_distributer_id: null,
        type: "aadhar",
        status: "",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        master_distributer_id: null,
        type: "pan",
        status: "",
        remark: "",
      }),
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",

      professionalKycAddar: "false",

      gstinVal: "false",
      proPassword: "false",
      pinCode: "false",
      businessPinCode: "false",
      bussinessCityMaster: "false",
      businessAddressMaster: "false",
      profesionalCity: "false",
      profesionalAddress: "false",
      altPhonePro: "false",
      phonePro: "false",
      bussinessNameMaster: "false",
      professionalName: "false",

      panVerifyForFirm: "false",
      submitStatusForFirm: "false",
    };
  },
  methods: {
    panVeryfied() {
      var data = this.form.bussinessPan;
      if (data.length == 10) {
        this.submitStatusForFirm = "false";
        $("#firmPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "admin/verifypan",
            { panNo: this.form.bussinessPan },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            console.log(res.data.data);
            if (res.data.data.message) {
              this.submitStatusForFirm = "true";
              $("#firmPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#firmPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.status == "VALID") {
              this.submitStatusForFirm = "false";
              $("#firmPanVeryfied").html("<small></small>");
              this.panVerifyForFirm = "true";
              toast.success("Pan Verified Succesfull", {
                autoClose: 1000,
              });
            } else {
              this.submitStatusForFirm = "true";
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatusForFirm == "true";
        $("#firmPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },

    loadFormNull() {
      this.form = {};
      this.edit = "false";
      this.form.errors = {};
      this.photo = this.$store.state.placeholderImg;
      this.msgImgError = "";

      this.panVerifyForFirm = "false";
      this.submitStatusForFirm = "false";
      $("#firmPanVeryfied").html("<small></small>");

      this.professionalKycAddar = "false";
      $("#professionalKycAddar").html("<small></small>");

      this.professionalName = "false";
      $("#professionalName").html("<small></small>");

      this.bussinessNameMaster = "false";
      $("#bussinessNameMaster").html("<small></small>");

      this.phonePro = "false";
      $("#phonePro").html("<small></small>");

      this.altPhonePro = "false";
      $("#altPhonePro").html("<small></small>");

      this.profesionalAddress = "false";
      $("#profesionalAddress").html("<small></small>");

      this.profesionalCity = "false";
      $("#profesionalCity").html("<small></small>");

      this.businessAddressMaster = "false";
      $("#businessAddressMaster").html("<small></small>");

      this.bussinessCityMaster = "false";
      $("#bussinessCityMaster").html("<small></small>");

      this.businessPinCode = "false";
      $("#businessPinCode").html("<small></small>");
      this.pinCode = "false";
      $("#pinCode").html("<small></small>");

      this.proPassword = "false";
      $("#proPassword").html("<small></small>");

      this.gstinVal = "false";
      $("#gstinVal").html("<small></small>");
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html(
            "<small>Aadhar required minimum 12 letters</small>"
          );
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }

      // master distributer create form

      if (type == "professionalName") {
        if (data.length < 3) {
          this.professionalName = "true";
          $("#professionalName").html("<small>Name required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.professionalName = "false";
          $("#professionalName").html("<small></small>");
        }
      }
      if (type == "bussinessNameMaster") {
        if (data.length < 3) {
          this.bussinessNameMaster = "true";
          $("#bussinessNameMaster").html(
            "<small> Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.bussinessNameMaster = "false";
          $("#bussinessNameMaster").html("<small></small>");
        }
      }

      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
          this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "profesionalCity") {
        if (data.length < 3) {
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      if (type == "businessAddressMaster") {
        if (data.length < 3) {
          this.businessAddressMaster = "true";
          $("#businessAddressMaster").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.businessAddressMaster = "false";
          $("#businessAddressMaster").html("<small></small>");
        }
      }

      if (type == "bussinessCityMaster") {
        if (data.length < 3) {
          this.bussinessCityMaster = "true";
          $("#bussinessCityMaster").html(
            "<small>City required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.bussinessCityMaster = "false";
          $("#bussinessCityMaster").html("<small></small>");
        }
      }

      if (type == "businessPinCode") {
        if (data.length != 6) {
          this.businessPinCode = "true";
          $("#businessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.businessPinCode = "false";
          $("#businessPinCode").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "proPassword") {
        if (data.length < 6) {
          if (data.length == 0) {
            this.proPassword = "false";
            $("#proPassword").html("<small></small>");
          } else {
            this.proPassword = "true";
            $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");
          }
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }
    },
    businessPanVeryfied() {
      var data = this.formKyc.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "admin/verifypan",
            { panNo: this.formKyc.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVarifyingStatus = "true";
              // this.$swal.fire({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Pan Verified Succesfully",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });

              toast.success("Pan Verified Succesfully", {
                autoClose: 1000,
              });
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus == "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadMasterDistributers();
    },
    aadharKycApprovedSave() {
      this.loading = true;
      this.$axios
        .post("admin/approvemasterdistributerkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          // console.log(res.data.data);

          toast.success("Aaddhar Kyc " + this.aadharapproveKyc.status + " Succesfully ", {
            autoClose: 1000,
          });
          this.loadMasterDistributers();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // console.log(error);
          error;
        });
    },
    panKycApprovedSave() {
      this.loading = true;
      this.$axios
        .post("admin/approvemasterdistributerkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          // console.log(res.data.data);

          toast.success("Pan Kyc " + this.panapproveKyc.status + " Succesfully ", {
            autoClose: 1000,
          });
          this.loadMasterDistributers();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          error;
          // console.log(error);
        });
    },
    masterDistributerKycApprovedSave() {
      this.loading = true;
      this.$axios
        .post("admin/approvemasterdistributerkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          // console.log(res.data.data);

          toast.success(
            "Master Distributer Kyc " + this.approveKyc.status + " Succesfully",
            {
              autoClose: 1000,
            }
          );

          $("#approoveKycModal").modal("hide");
          this.loadMasterDistributers();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.$swal.fire({
            position: "top-center",
            icon: "error",
            title: "<small>" + error.response.data.error + "</small>",
            showConfirmButton: true,
            confirmButtonColor: "#00364f",
          });
        });
    },
    valueAssign(kyc, id) {
      console.log(kyc);
      this.aadharNoForKyc = kyc.aadharNo;
      // this.accountNoForKyc = kyc.accountNo
      this.panNoForKyc = kyc.panNo;
      // this.accountapproveKyc.master_distributer_id = id
      this.panapproveKyc.master_distributer_id = id;
      this.panapproveKyc.status = kyc.panStatus;
      this.aadharapproveKyc.master_distributer_id = id;
      this.aadharapproveKyc.status = kyc.aadharStatus;
      this.approveKyc.master_distributer_id = id;
      this.approveKyc.status = kyc.kycStatus;

      if (kyc.remark) {
        this.approveKyc.remark = kyc.remark;
      }

      if (kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/masterdistributer/' + this.admin_id + '/thumbs/' + this.form.panPhoto
        if (
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          id +
          "/thumbs/" +
          kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            id +
            "/thumbs/" +
            kyc.panPhoto;
        }
        if (this.$store.state.imgUrl + "/masterdistributer/" + id + "/" + kyc.panPhoto) {
          this.panImg =
            this.$store.state.imgUrl + "/masterdistributer/" + id + "/" + kyc.panPhoto;
        }

        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      // if (kyc.passbookPhoto != null) {
      //     this.passBookImg = this.$store.state.imgUrl + '/masterdistributer/' + id + '/thumbs/' + kyc.passbookPhoto
      // } else {
      //     this.passBookImg = this.$store.state.placeholderImg
      // }

      if (kyc.aadharBackPhoto != null) {
        // this.adharBackImg = this.$store.state.imgUrl + '/masterdistributer/' + this.admin_id + '/thumbs/' + this.form.aadharBackPhoto

        if (
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          id +
          "/thumbs/" +
          kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            id +
            "/thumbs/" +
            kyc.aadharBackPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          id +
          "/" +
          kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            id +
            "/" +
            kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }

      if (kyc.aadharFrontPhoto != null) {
        // this.adharFrontImg = this.$store.state.imgUrl + '/masterdistributer/' + this.admin_id + '/thumbs/' + this.form.aadharFrontPhoto

        if (
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          id +
          "/thumbs/" +
          kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            id +
            "/thumbs/" +
            kyc.aadharFrontPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          id +
          "/" +
          kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            id +
            "/" +
            kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      // alert(this.adharFrontImg)
    },

    openWindow(url) {
      window.open(`${url}`);
    },
    masterDistributerkycValueAsign(value) {
      // console.log(value.distributer_kyc)

      this.professionalKycAddar = "false";
      $("#professionalKycAddar").html("<small></small>");

      this.msgImgError = "";
      this.formKyc = {};
      this.formKyc.errors = {};

      this.panVarifyingStatus = "false";
      this.masterDistributer_id = value.id;
      if (value.master_distributer_kyc == null) {
        // alert('distributerkycCreate')
        this.kycModalHeading = "Create";
        this.masterdistributerKycVal = "false";

        this.formKyc.panNo = "";
        this.formKyc.panPhoto = "";
        this.formKyc.accountType = "";
        this.formKyc.aadharNo = "";
        this.formKyc.remark = "";
        this.formKyc.aadharBackPhoto = "";
        this.formKyc.aadharFrontPhoto = "";
        this.formKyc.passbookPhoto = "";
        this.formKyc.accountNo = "";
        this.adharBackImg = this.$store.state.placeholderImg;
        this.adharFrontImg = this.$store.state.placeholderImg;
        this.panImg = this.$store.state.placeholderImg;
        this.passBookImg = this.$store.state.placeholderImg;
      } else {
        // alert('edit')
        this.kycModalHeading = "Update";
        // var data = value;
        // alert('this is the distributer id  distributerkycValueAsign' + this.masterDistributer_id)
        this.formKyc = value.master_distributer_kyc;
        this.formKyc.panNo = value.master_distributer_kyc.panNo;
        this.panVarifyingStatus = "true";
        this.formKyc.aadharNo = value.master_distributer_kyc.aadharNo;
        this.formKyc.accountNo = value.master_distributer_kyc.accountNo;

        // if (this.formKyc.panPhoto != null) {
        //     this.panImg = this.$store.state.imgUrl + '/masterdistributer/'+ this.masterDistributer_id + '/thumbs/' + this.formKyc.panPhoto
        // } else {
        //     this.panImg = this.$store.state.placeholderImg
        // }

        if (this.formKyc.panPhoto != null) {
          // this.panImg = this.$store.state.imgUrl + '/masterdistributer/' + this.admin_id + '/thumbs/' + this.form.panPhoto

          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer_id +
            "/" +
            this.formKyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/" +
              this.formKyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer_id +
            "/thumbs/" +
            this.formKyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/thumbs/" +
              this.formKyc.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.passbookPhoto != null) {
          this.passBookImg =
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer_id +
            "/thumbs/" +
            this.formKyc.passbookPhoto;
        } else {
          this.passBookImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.aadharFrontPhoto != null) {
          // this.adharFrontImg = this.$store.state.imgUrl + '/masterdistributer/' + this.admin_id + '/thumbs/' + this.formKyc.aadharFrontPhoto
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer_id +
            "/" +
            this.formKyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/" +
              this.formKyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer_id +
            "/thumbs/" +
            this.formKyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/thumbs/" +
              this.formKyc.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.aadharBackPhoto != null) {
          if ((this.formKyc.aadharBackPhoto != null) != null) {
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/" +
              this.formKyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer_id +
                "/" +
                this.formKyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer_id +
              "/thumbs/" +
              this.formKyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer_id +
                "/thumbs/" +
                this.formKyc.aadharBackPhoto;
            }

            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }
        }

        this.masterdistributerKycVal = "true";
        // console.log(data);
      }
      // this.loaddistributers()
      // this.distributerKycLoad()
    },
    masterDistributerkycCreate() {
      if (this.professionalKycAddar == "false" && this.panVarifyingStatus == "true") {
        const formData = new FormData();
        const keys = Object.keys(this.formKyc);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.formKyc[keys[n]] !== null) {
            formData.append(keys[n], this.formKyc[keys[n]]);
          }
        }
        formData.append("master_distributer_id", this.masterDistributer_id);
        // console.log(formData);
        // alert('befor distributer kyc create ')
        this.loading = true;
        this.$axios
          .post("admin/masterdistributerkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            // console.log(res.data.data);

            toast.success("Kyc Created Succesfully ", {
              autoClose: 1000,
            });
            $("#kycModal").modal("hide");

            this.formKyc.panNo = "";
            this.formKyc.panPhoto = "";
            this.formKyc.accountType = "";
            this.formKyc.aadharNo = "";
            this.formKyc.remark = "";
            this.formKyc.aadharBackPhoto = "";
            this.formKyc.aadharFrontPhoto = "";
            this.formKyc.passbookPhoto = "";
            this.formKyc.accountNo = "";
            this.adharBackImg = this.$store.state.placeholderImg;
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;

            this.loadMasterDistributers();
            this.panVarifyingStatus = "false";
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            console.log("res.data.data");
            console.log(error.response.data);
            console.log("res.data.data");
            this.formKyc.errors = error.response.data;
            console.log(this.formKyc.errors);
            console.log("res.data.data");
          });
      }
    },
    masterDistributerkycUpdate() {
      if (this.professionalKycAddar == "false" && this.panVarifyingStatus == "true") {
        const formData = new FormData();
        const keys = Object.keys(this.formKyc);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.formKyc[keys[n]] !== null) {
            formData.append(keys[n], this.formKyc[keys[n]]);
          }
        }
        formData.append("master_distributer_id", this.masterDistributer_id);
        this.loading = true;
        this.$axios
          .post(`admin/masterdistributerkyc/${this.formKyc.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            // console.log(res.data.data);
            toast.success("Kyc Updated Succesfully ", {
              autoClose: 1000,
            });
            $("#kycModal").modal("hide");
            this.formKyc.panNo = "";
            this.formKyc.panPhoto = "";
            this.formKyc.accountType = "";
            this.formKyc.aadharNo = "";
            this.formKyc.remark = "";
            this.formKyc.aadharBackPhoto = "";
            this.formKyc.aadharFrontPhoto = "";
            this.formKyc.passbookPhoto = "";
            this.formKyc.accountNo = "";
            this.adharBackImg = this.$store.state.placeholderImg;
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;
            this.passBookImg = this.$store.state.placeholderImg;
            this.loadMasterDistributers();
          })
          .catch((error) => {
            this.formKyc.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    uploadAdharFront(e) {
      // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.aadharFrontPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#AdharFront").value;
      // this.extensionkycAdharFront = fileName.split(".").pop();

      // if (this.extensionkycAdharFront == "pdf") {
      //   this.adharFrontImg = "/assets/image/pdf.png";
      // }



      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharFront").value;
      this.extensionkycAdharFront = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PNG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharFrontPhoto = e.target.files["0"];

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadAdharBack(e) {
      // this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.aadharBackPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#AdharBack").value;
      // this.extensionkycAdharBack = fileName.split(".").pop();

      // if (this.extensionkycAdharBack == "pdf") {
      //   this.adharBackImg = "/assets/image/pdf.png";
      // }




      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharBack").value;
      this.extensionkycAdharBack = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "PNG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PDF"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharBackPhoto = e.target.files["0"];

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Back File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadPassbookPhoto(e) {
      this.passBookImg = URL.createObjectURL(e.target.files["0"]);
      this.formKyc.passbookPhoto = e.target.files["0"];
    },
    uploadPanPhoto(e) {
      // this.panImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.panPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#panphoto").value;
      // this.extensionkycPanPhoto = fileName.split(".").pop();

      // if (this.extensionkycPanPhoto == "pdf") {
      //   this.panImg = "/assets/image/pdf.png";
      // }




      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#panphoto").value;
      this.extensionkycPanPhoto = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "PNG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PDF"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.panPhoto = e.target.files["0"];

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Pan File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    profilePhotoFinder(masterDistributer) {
      var photo;
      if (masterDistributer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          masterDistributer.id +
          "/thumbs/" +
          masterDistributer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    uploadImg(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#file-input1").value;
        this.extensionProfile = fileName.split(".").pop();
        if (
          this.extensionProfile == "png" ||
          this.extensionProfile == "jpg" ||
          this.extensionProfile == "jpeg" ||
          this.extensionProfile == "PNG" ||
          this.extensionProfile == "JPG" ||
          this.extensionProfile == "JPEG"
        ) {
          // this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
          // this.form.photo = e.target.files["0"];

          this.photo = URL.createObjectURL(e.target.files["0"]);
          this.form.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },
    masterDistributerrUpdate() {
      if (
        this.gstinVal == "false" &&
        this.proPassword == "false" &&
        this.pinCode == "false" &&
        this.businessPinCode == "false" &&
        this.bussinessCityMaster == "false" &&
        this.businessAddressMaster == "false" &&
        this.profesionalCity == "false" &&
        this.profesionalAddress == "false" &&
        this.altPhonePro == "false" &&
        this.phonePro == "false" &&
        this.bussinessNameMaster == "false" &&
        this.professionalName == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`admin/masterdistributer/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            // alert('after distributer kyc create ')
            // console.log(res.data.data);
            res;
            toast.success("Updated Succesfully", {
              autoClose: 1000,
            });

            this.form = {};
            this.form.errors = {};
            $("#masterDistributerModal").modal("hide");
            this.loadMasterDistributers();
          })
          .catch((error) => {
            console;
            this.form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    masterDistributerValueAssign(masterDistributer) {
      this.professionalKycAddar = "false";
      $("#professionalKycAddar").html("<small></small>");

      this.professionalName = "false";
      $("#professionalName").html("<small></small>");

      this.bussinessNameMaster = "false";
      $("#bussinessNameMaster").html("<small></small>");

      this.phonePro = "false";
      $("#phonePro").html("<small></small>");

      this.altPhonePro = "false";
      $("#altPhonePro").html("<small></small>");

      this.profesionalAddress = "false";
      $("#profesionalAddress").html("<small></small>");

      this.profesionalCity = "false";
      $("#profesionalCity").html("<small></small>");

      this.businessAddressMaster = "false";
      $("#businessAddressMaster").html("<small></small>");

      this.bussinessCityMaster = "false";
      $("#bussinessCityMaster").html("<small></small>");

      this.businessPinCode = "false";
      $("#businessPinCode").html("<small></small>");
      this.pinCode = "false";
      $("#pinCode").html("<small></small>");

      this.proPassword = "false";
      $("#proPassword").html("<small></small>");

      this.gstinVal = "false";
      $("#gstinVal").html("<small></small>");

      // this.heading = 'Update'
      this.msgImgError = "";
      this.form = masterDistributer;

      if (masterDistributer.bussinessPan) {
        this.panVerifyForFirm = "true";

        $("#firmPanVeryfied").html("<small></small>");
      } else {
        this.panVerifyForFirm = "false";
        this.submitStatusForFirm = "false";
        $("#firmPanVeryfied").html("<small></small>");
      }

      this.edit = "true";

      if (masterDistributer.photo != null) {
        this.form.photo =
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          masterDistributer.id +
          "/thumbs/" +
          masterDistributer.photo;
        this.photo = this.form.photo;
      } else {
        this.photo = this.$store.state.placeholderImg;
      }
    },
    createMasterDistributer() {
      if (
        this.gstinVal == "false" &&
        this.submitStatusForFirm == "false" &&
        this.proPassword == "false" &&
        this.pinCode == "false" &&
        this.businessPinCode == "false" &&
        this.bussinessCityMaster == "false" &&
        this.businessAddressMaster == "false" &&
        this.profesionalCity == "false" &&
        this.profesionalAddress == "false" &&
        this.altPhonePro == "false" &&
        this.phonePro == "false" &&
        this.bussinessNameMaster == "false" &&
        this.professionalName == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post("admin/masterdistributer", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("Created Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            $("#masterDistributerModal").modal("hide");
            this.loadMasterDistributers();
          })
          .catch((error) => {
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data,
            });
          })
          .finally(() => (this.loading = false));
        // .catch((error) => {

        //  console.log(error.response.data)
        //   this.form.errors = error.response.data;
        // })
      }
    },
    states() {
      // this.loading = true
      this.$axios
        .get("admin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data);
          this.allstates = res.data.data;
        })
        // .finally(() => (this.loading = false))
        .catch((error) => {
          // console.log(error);
          error;
        });
    },
    valueNullFilter() {
      this.filterValue = "";
    },
    loadMasterDistributers(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
          if (this.filterType == "name") {
            pageUrl += `admin/masterdistributer?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `admin/masterdistributer?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `admin/masterdistributer?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.masterDistributers = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle() {
      console.log("--------------------------");

      document.title = this.$store.state.adminPageTitles.mstDistributer;
      console.log(this.$store.state.adminPageTitles.mstDistributer);
      console.log("--------------------------");
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadMasterDistributers();
      this.states();
      this.loadPageTitle();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}

.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
